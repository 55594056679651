<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <div
        v-if="loaderData"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Min Android Version"
            rules="required"
          >
            <b-form-group
              label="Min Android Version"
              label-for="Min Android Version"
            >
              <b-form-input
                id="Min Android Version"
                v-model="AppVersionForm.min_android_version"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Min Android Version"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Max Android Version -->
          <validation-provider
            #default="validationContext"
            name="Max Android Version"
            rules="required"
          >
            <b-form-group
              label="Max Android Version"
              label-for="Max Android Version"
            >
              <b-form-input
                id="Max Android Version"
                v-model="AppVersionForm.max_android_version"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Max Android Version"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Min ios version -->
          <validation-provider
            #default="validationContext"
            name="Min ios version"
            rules="required"
          >
            <b-form-group
              label="Min ios version"
              label-for="Min ios version"
            >
              <b-form-input
                id="Min ios version"
                v-model="AppVersionForm.min_ios_version"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Min ios version"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- max ios version -->
          <validation-provider
            #default="validationContext"
            name="max ios version"
            rules="required"
          >
            <b-form-group
              label="max ios version"
              label-for="max ios version"
            >
              <b-form-input
                id="max ios version"
                v-model="AppVersionForm.max_ios_version"

                :state="getValidationState(validationContext)"
                trim
                placeholder="max ios version"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addAppVersion()"
          >
            Update
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {
  },
  data() {
    return {
      id: this.$route.params.id,
      isVoucher: false,
      required,
      allSelected: [],
      indeterminate: false,
      countries: [],
      users: [],
      loader: false,
      loaderData: false,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const AppVersion_images = ref([])

    const AppVersionForm = ref({

    })
    return {
      getValidationState,
      AppVersionForm,
      AppVersion_images,
    }
  },
  mounted() {
    this.showAppVersion()
    this.getCountries()
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    deleteImg(id) {
      axios.get(`admin/delete_image/${id}`).then(res => {
        if (res.status === 200) {
          this.showAppVersion()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        // this.formData = new FormData()
      }).finally(() => {
        this.loader = false
      })
    },

    showAppVersion() {
      this.loaderData = true

      axios.get('admin/app_version').then(res => {
        if (res.status === 200) {
          this.AppVersionForm = res.data.data.versions
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        // this.formData = new FormData()
      }).finally(() => {
        this.loaderData = false
      })
    },
    addAppVersion() {
      this.loader = true
      const formData = new FormData()

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.AppVersionForm) {
        formData.append(key, this.AppVersionForm[key])
      }

      axios.post('admin/update_app_version', formData).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.$router.push({ name: 'app-version' })
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Somthing Went Wrong',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}

</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .deleteImgIcon{
    cursor: pointer;
    color: grey;
  }
  </style>
